<template>
<b-card v-if="users" no-body class="card-company-table">
    <b-table :items="users" responsive :fields="fields" class="mb-0">

        <!-- Id -->
        <template #cell(id)="data">
            <span class="font-weight-bolder mb-12">#{{data.index+1 }}</span>
        </template>

        <!-- CreatedAt Time -->
        <template #cell(createdAt)="data">
            <span class="font-weight-bolder mb-12">{{
                data.item.createdAt | moment("DD.MM.YYYY hh:mm")
                }}</span>
        </template>

        <!-- Start Time -->
        <template #cell(startDate)="data">
            <span class="font-weight-bolder mb-12">{{
                data.item.startDate | moment("DD.MM.YYYY hh:mm")
                }}</span>
        </template>

        <!-- End Time -->
        <template #cell(endDate)="data">
            <span class="font-weight-bolder mb-12">{{
                data.item.endDate | moment("DD.MM.YYYY hh:mm")
                }}</span>
        </template>
        <!-- Actions -->
        <template #cell(actions)="data">
            <b-button variant="flat-primary" @click="uploadItem(data.item.id)">
                <feather-icon icon="LinkIcon" class="mr-50" />
                <span class="align-middle">Yükle</span>
            </b-button>
            <b-button variant="flat-primary" :to="{ name: 'report-card-item-page', params: { id: data.item.id } }">
                <feather-icon icon="LinkIcon" class="mr-50" />
                <span class="align-middle">Detaylar</span>
            </b-button>
            <b-button variant="flat-danger" @click="deleteExam(data.item)">
                <feather-icon icon="DeleteIcon" class="mr-50" />
                <span class="align-middle">Sil</span>
            </b-button>
        </template>

    </b-table>

    <!--Open Add Exam-->
    <b-sidebar sidebar-class="sidebar-lg" bg-variant="white" shadow backdrop no-header right v-model="addExamPanel">
        <template #default="{ hide }">
            <!-- Header -->
            <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
                <h5 class="mb-0">Yeni Deneme Karnesi Ekle</h5>
                <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
            </div>

            <!-- Body -->
            <b-form class="p-2" @submit.prevent>
                <b-form-group label="Name" label-for="name">
                    <b-form-input name="firstName" id="name" type="text" v-model="addExamRequest.name" />
                </b-form-group>
                <b-form-group label="Başlangıç Tarihi" label-for="name">
                    <flat-pickr v-model="addExamRequest.startDate" class="form-control" :config="{ enableTime: true,time_24hr:true}" />
                </b-form-group>
                <b-form-group label="Bitiş Tarihi" label-for="name">
                    <flat-pickr v-model="addExamRequest.endDate" class="form-control" :config="{ enableTime: true,time_24hr:true}" />
                </b-form-group>
                <!-- Form Actions -->
                <div class="d-flex mt-2">
                    <b-button variant="primary" class="mr-2" type="submit" @click="addExam">
                        Ekle
                    </b-button>
                    <b-button variant="outline-secondary" @click="hide">
                        Vazgeç
                    </b-button>
                </div>
            </b-form>
        </template>
    </b-sidebar>

    <b-modal v-model="showUpload" title="Excell Soru Yükleme" hide-footer size="lg">
        <p v-if="fileResponse == null">
            Optik okuyucudan alınan excell dosyasını yükleyin.
        </p>
        <p v-else v-html="fileResponse" />
        <p v-if="fileResponse == null">
            <b-button block variant="primary" class="font-weight-bolder mb-12" @click="$refs.files.click()">{{ loading? "Yükleniyor Lütfen Bekleyin" : "YÜKLE" }}</b-button>
        </p>
        <input style="display: none" type="file" id="files" ref="files" v-on:change="handleFileUploads()" />
    </b-modal>

</b-card>
</template>

<script>
import {
    BCard,
    BTable,
    BAvatar,
    BImg,
    BBadge,
    BSidebar,
    BTooltip,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BInputGroupPrepend,
    BInputGroup,
    BFormDatepicker
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
export default {
    components: {
        flatPickr,
        vSelect,
        ToastificationContent,
        BCard,
        BTable,
        BAvatar,
        BImg,
        BBadge,
        BSidebar,
        BTooltip,
        BForm,
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BMediaBody,
        BMedia,
        BMediaAside,
        BFormCheckbox,
        BInputGroupPrepend,
        BInputGroup,
        BFormDatepicker,
    },
    data() {
        return {
            fields: [
                { key: 'id', label: 'Ref' },
                { key: 'createdTime', label: 'Kayıt Tarihi' },
                { key: 'name', label: 'Başlık' },
                { key: 'startDate', label: 'Başlangıç Tarihi' },
                { key: 'endDate', label: 'Bitiş Tarihi' },
                { key: 'actions', label: 'Detaylar' },
            ],
            users: [],
            addExamPanel: false,
            addExamRequest: {
                name: '',
                startDate: '',
                endDate: '',
            },
            showUpload: false,
            selectedExamId:null,
            selectedReportId:null,
            loading:false,
            fileResponse:null
        }
    },
    mounted() {
        this.$root.$on('AddButtonClick', () => {
            this.addExamPanel = true;
        });
        this.$root.$on("AddUploadClick", () => {
            this.showUpload = true;
        });
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            var users = await this.$http.get("Report/ReportCard");
            this.users = users.data.data;
        },
        addExam() {
            var request = this.addExamRequest;
            this.$http.post("Report/AddReportCard", request).then((data) => {
                    this.getData();
                    this.addExamPanel = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'İşlem',
                            icon: 'BellIcon',
                            text: "İşlem Başarılı Test Oluşturuldu",
                            variant: 'success'
                        },
                    });
                })
                .catch((error) => {
                    //console.log(error.response.data)
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Hata',
                            icon: 'BellIcon',
                            text: error.response.data.error.message,
                            variant: 'danger'
                        },
                    });
                });
        },
        deleteExam(data) {
            this.$confirm({
                title: 'Sil',
                message: `Silmek İstediğinizden Emin misiniz?`,
                button: {
                    no: 'Vazgeç',
                    yes: 'Sil'
                },
                callback: async confirm => {
                    if (confirm) {
                        await this.$http.delete("Report/ReportCardItem/" + data.id);
                        this.getData();
                    }
                }
            });
        },
        uploadItem(id){
            this.selectedReportId = id;
            this.showUpload = true;
        },
        async handleFileUploads() {
            var file = this.$refs.files.files[0];
            if(file == null) { alert("Dosya Seçilmedi"); return;}
            
            let form = new FormData();
            form.append("file",file);
            this.loading=true;
            const response = await this.$http.post("Report/ReportCardItem/"+this.selectedReportId,form);
            this.fileResponse = response.data;
            this.loading=false;
        }
    }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
    .dark-layout & {
        background: $theme-dark-body-bg !important;
    }
}
</style>
